@media only screen and (max-width: 600px) {
    .dance-sm-shower {
        font-family: 'Dancing Script', cursive;
        font-weight: bolder;
        font-style: normal;
        font-size: 3.5vw;
        white-space: nowrap;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        letter-spacing: 2px;
    }
    .title-shower p {
        color: #564861;
        margin-top: 5px;
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 2.3vw;
        white-space: nowrap;
    }  
    .downloadButton-shower {
        display: none;
    }   
    .venue-shower {
        width: 20vw;
        height: 25px;
        border-radius: 16px;
        text-align: center;
        font-size: 1.3vw;
        color: #fff;
        padding: 8px;
        margin-right: 8px;
        margin-top: 10px;
        cursor: pointer;
    }
    .happiness-shower {
        margin: 0 auto;
        width: 400px;
        color: #874562;
        font-size: 2vw;
        font-weight: bold;
        text-align: center;
    }
    .invitation-footer1-shower {
        color: #564861;
        text-align: center;
        font-size: 2.7vw;
        margin-top: 20px;
        margin-bottom: 0px;
        background-color: transparent;
    }
    .invitation-footer2-shower {
        color: #564861;
        text-align: center;
        font-size: 1.7vw;
        margin-top: 5px;
        margin-bottom: 20px;
        background-color: transparent;
    }
    .wreath-shower {
        position: absolute;
        left: 50%;
        top:0px;
        margin-left: -237.5px;
        margin-top: -90px;
        background-repeat: no-repeat;
        background-image: url('../../../assets/img/Other/goldWreath.png');
        min-width: 475px;
        min-height: 405px;
        transform: scale(0.4);
    }
    .centerInitialMan-shower {
        position: relative;
        padding-top: 70%;
        color:black;
        font-size: 8vmax;
        left: 50%;
        display: block;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .centerInitial-shower {
        position: relative;
        padding-top: 70%;
        color:black;
        font-size: 10vmax;
        left: 50%;
        display: block;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .wreath-header-style-shower {
        color: black !important;
    }
    .top-center-decoration-shower {
        transform: scale(.6);
        top: 0;
        align-items: center;
        text-align: center;
        margin: auto;
        display: block;
    }
    .bottom-center-decoration-shower {
        margin-left: auto;
        transform: scale(.6);
        display: block;
        margin-right: auto;
    }
    .powered {
        position: absolute;
        cursor: pointer;
        font-family: "Oxanium" !important;
    }
    .powered a:hover{
        text-decoration: none;
    }
}

@media only screen and (min-width: 601px) {
    .dance-sm-shower {
        font-family: 'Dancing Script', cursive;
        font-weight: bolder;
        font-style: normal;
        font-size: 1.5vw;
        white-space: nowrap;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        letter-spacing: 2px;
    }
    .title-shower p {
        color: #564861;
        margin-top: 5px;
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 1.1vw;
        white-space: nowrap;
    }    
    .venue-shower {
        width: 7vw;
        height: 35px;
        border-radius: 16px;
        text-align: center;
        font-size: .6vw;
        color: #fff;
        padding: 8px;
        margin-right: 8px;
        margin-top: 10px;
        cursor: pointer;
    }
    .happiness-shower {
        margin: 0 auto;
        width: 400px;
        color: #874562;
        font-size: .9vw;
        font-weight: bold;
        text-align: center;
    }
    .invitation-footer1-shower {
        color: #564861;
        text-align: center;
        font-size: 1.2vw;
        margin-top: 20px;
        margin-bottom: 0px;
        background-color: transparent;
    }
    .invitation-footer2-shower {
        color: #564861;
        text-align: center;
        font-size: .7vw;
        margin-top: 5px;
        margin-bottom: 20px;
        background-color: transparent;
    }
    .wreath-shower {
        position: absolute;
        left: 50%;
        top:0px;
        margin-top: -90px;
        margin-left: -237.5px;
        background-repeat: no-repeat;
        background-image: url('../../../assets/img/Other/goldWreath.png');
        min-width: 475px;
        min-height: 405px;
        transform: scale(0.4);
    }
    .centerInitialMan-shower {
        position: relative;
        padding-top: 70%;
        color:black;
        font-size: 60px;
        left: 50%;
        display: block;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .centerInitial-shower {
        position: relative;
        padding-top: 70%;
        color:black;
        font-size: 80px;
        left: 50%;
        display: block;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .wreath-header-style-shower {
        color: black !important;
        font-size: .8vw !important;
    }
    .top-center-decoration-shower {
        top: 0;
        align-items: center;
        text-align: center;
        height: 70px;
        margin: auto;
        display: block;
    }
    .bottom-center-decoration-shower {
        margin-top: 40px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 70px;
    }
    .powered {
        bottom: 0;
        left: 0;
        position: absolute;
        cursor: pointer;
        font-family: "Oxanium" !important;
    }
    .powered a:hover{
        text-decoration: none;
    }
}