@-webkit-keyframes fall {
    0% {
        opacity: 0.9;
        top: 0
    }
    100% {
        opacity: 0.2;
        top: 100%
    }
}

@keyframes fall {
    0% {
        opacity: 0.9;
        top: 0
    }
    100% {
        opacity: 0.2;
        top: 100%
    }
}

@-webkit-keyframes blow-soft-left {
    0% {
        margin-left: 0
    }
    100% {
        margin-left: -50%
    }
}

@keyframes blow-soft-left {
    0% {
        margin-left: 0
    }
    100% {
        margin-left: -50%
    }
}

@-webkit-keyframes blow-medium-left {
    0% {
        margin-left: 0
    }
    100% {
        margin-left: -100%
    }
}

@keyframes blow-medium-left {
    0% {
        margin-left: 0
    }
    100% {
        margin-left: -100%
    }
}

@-webkit-keyframes blow-soft-right {
    0% {
        margin-left: 0
    }
    100% {
        margin-left: 50%
    }
}

@keyframes blow-soft-right {
    0% {
        margin-left: 0
    }
    100% {
        margin-left: 50%
    }
}

@-webkit-keyframes blow-medium-right {
    0% {
        margin-left: 0
    }
    100% {
        margin-left: 100%
    }
}

@keyframes blow-medium-right {
    0% {
        margin-left: 0
    }
    100% {
        margin-left: 100%
    }
}

@-webkit-keyframes sway-0 {
    0% {
        -webkit-transform: rotate(-5deg)
    }
    40% {
        -webkit-transform: rotate(28deg)
    }
    100% {
        -webkit-transform: rotate(3deg)
    }
}

@keyframes sway-0 {
    0% {
        -ms-transform: rotate(-5deg);
        transform: rotate(-5deg)
    }
    40% {
        -ms-transform: rotate(28deg);
        transform: rotate(28deg)
    }
    100% {
        -ms-transform: rotate(3deg);
        transform: rotate(3deg)
    }
}

@-webkit-keyframes sway-1 {
    0% {
        -webkit-transform: rotate(10deg)
    }
    40% {
        -webkit-transform: rotate(43deg)
    }
    100% {
        -webkit-transform: rotate(15deg)
    }
}

@keyframes sway-1 {
    0% {
        -ms-transform: rotate(10deg);
        transform: rotate(10deg)
    }
    40% {
        -ms-transform: rotate(43deg);
        transform: rotate(43deg)
    }
    100% {
        -ms-transform: rotate(15deg);
        transform: rotate(15deg)
    }
}

@-webkit-keyframes sway-2 {
    0% {
        -webkit-transform: rotate(15deg)
    }
    40% {
        -webkit-transform: rotate(56deg)
    }
    100% {
        -webkit-transform: rotate(22deg)
    }
}

@keyframes sway-2 {
    0% {
        -ms-transform: rotate(15deg);
        transform: rotate(15deg)
    }
    40% {
        -ms-transform: rotate(56deg);
        transform: rotate(56deg)
    }
    100% {
        -ms-transform: rotate(22deg);
        transform: rotate(22deg)
    }
}

@-webkit-keyframes sway-3 {
    0% {
        -webkit-transform: rotate(25deg)
    }
    40% {
        -webkit-transform: rotate(74deg)
    }
    100% {
        -webkit-transform: rotate(37deg)
    }
}

@keyframes sway-3 {
    0% {
        -ms-transform: rotate(25deg);
        transform: rotate(25deg)
    }
    40% {
        -ms-transform: rotate(74deg);
        transform: rotate(74deg)
    }
    100% {
        -ms-transform: rotate(37deg);
        transform: rotate(37deg)
    }
}

@-webkit-keyframes sway-4 {
    0% {
        -webkit-transform: rotate(40deg)
    }
    40% {
        -webkit-transform: rotate(68deg)
    }
    100% {
        -webkit-transform: rotate(25deg)
    }
}

@keyframes sway-4 {
    0% {
        -ms-transform: rotate(40deg);
        transform: rotate(40deg)
    }
    40% {
        -ms-transform: rotate(68deg);
        transform: rotate(68deg)
    }
    100% {
        -ms-transform: rotate(25deg);
        transform: rotate(25deg)
    }
}

@-webkit-keyframes sway-5 {
    0% {
        -webkit-transform: rotate(50deg)
    }
    40% {
        -webkit-transform: rotate(78deg)
    }
    100% {
        -webkit-transform: rotate(40deg)
    }
}

@keyframes sway-5 {
    0% {
        -ms-transform: rotate(50deg);
        transform: rotate(50deg)
    }
    40% {
        -ms-transform: rotate(78deg);
        transform: rotate(78deg)
    }
    100% {
        -ms-transform: rotate(40deg);
        transform: rotate(40deg)
    }
}

@-webkit-keyframes sway-6 {
    0% {
        -webkit-transform: rotate(65deg)
    }
    40% {
        -webkit-transform: rotate(92deg)
    }
    100% {
        -webkit-transform: rotate(58deg)
    }
}

@keyframes sway-6 {
    0% {
        -ms-transform: rotate(65deg);
        transform: rotate(65deg)
    }
    40% {
        -ms-transform: rotate(92deg);
        transform: rotate(92deg)
    }
    100% {
        -ms-transform: rotate(58deg);
        transform: rotate(58deg)
    }
}

@-webkit-keyframes sway-7 {
    0% {
        -webkit-transform: rotate(72deg)
    }
    40% {
        -webkit-transform: rotate(118deg)
    }
    100% {
        -webkit-transform: rotate(68deg)
    }
}

@keyframes sway-7 {
    0% {
        -ms-transform: rotate(72deg);
        transform: rotate(72deg)
    }
    40% {
        -ms-transform: rotate(118deg);
        transform: rotate(118deg)
    }
    100% {
        -ms-transform: rotate(68deg);
        transform: rotate(68deg)
    }
}

@-webkit-keyframes sway-8 {
    0% {
        -webkit-transform: rotate(94deg)
    }
    40% {
        -webkit-transform: rotate(136deg)
    }
    100% {
        -webkit-transform: rotate(82deg)
    }
}

@keyframes sway-8 {
    0% {
        -ms-transform: rotate(94deg);
        transform: rotate(94deg)
    }
    40% {
        -ms-transform: rotate(136deg);
        transform: rotate(136deg)
    }
    100% {
        -ms-transform: rotate(82deg);
        transform: rotate(82deg)
    }
}
.top-right-decoration-sakura {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    max-width: 25%;
}
.top-left-decoration-sakura {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    max-width: 25%;
}
.bottom-right-decoration-sakura {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    max-width: 25%;
}
.bottom-left-decoration-sakura {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    max-width: 25%;
}
.sakura-venue-shower {
    background-color: #674ea7;
}
.sakura {
    /* background: -webkit-linear-gradient(120deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9));
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9));*/
    background: red;
    pointer-events: none;
    position: absolute
}
.sakura {
    background: #674ea7;
}
.sakura-happiness-shower {
    color: #674ea7 !important;
}
.sakura-man-name-shower {
    color: #674ea7;
}
.sakura-woman-name-shower {
    color: #674ea7;
}
.sakura-and-sign-shower {
    color: #e25c67;
}
.sakura-dance-sm-shower {
    color: #564861;
}
.sakura-title-shower {
    margin-top: 80px !important;  
    margin-bottom: 0px;
}
.body-style-sakura-shower {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }
 .sakura-shower-style {
    border: 2px solid #564861;
    border-radius: 8px;
    height: 100%;
    max-width: 100%;
    margin: 10px;
    background-image: url("../../../assets/img/Other/texturedBackground.png");
}