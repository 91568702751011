.FloralFrame-man-name-wedding {
    color: #d9af43 !important;
}
.FloralFrame-and-sign-wedding {
    color: #D9AF43 !important;
}
.FloralFrame-woman-name-wedding {
    color: #d9af43 !important;
}
.FloralFrame-venue-wedding {
    background-color: #d9af43;
}
.FloralFrame-happiness-wedding {
    color: #d9af43 !important;
}
.FloralFrame-title-wedding {
    margin-top: 80px !important;  
    margin-bottom: 0px;
}
.FloralFrame-dance-sm-wedding {
    color: #D9AF43 !important;
}
.FloralFrame-invitation-footer-wedding {
    color: #D9AF43 !important;
}
.FloralFrame-bottom-right-text-wedding {
    margin-bottom: 60px;
    margin-top: 0px;
    text-align: center;
    color: #D9AF43;
}
.FloralFrame-bottom-left-text-wedding {
    color: #D9AF43;
    letter-spacing: 3px;
    padding-top: 5px;
    text-align: left;
}
.FloralFrame-bottom-right-text-wedding {
    color: #D9AF43;
    letter-spacing: 3px;
    padding-top: 5px;
    display: inline;
    float: right;
}
.body-style-FloralFrame-wedding {
    zoom:75%;
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }
.FloralFrame-wedding-style {
    border: 2px solid #D9AF43;
    border-radius: 8px;
    height: 100%;
    max-width: 100%;
    margin: 10px;
    max-height: 1200px;
    background-image: url("../../../assets/img/Other/texturedBackground.png");
}