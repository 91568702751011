.classic-man-name-baby {
    color: black !important;
}
.classic-and-sign-baby {
    color: black !important;
}
.classic-woman-name-baby {
    color: black !important;
}
.classic-happiness-baby {
    color: black !important;
}
.classic-invitation-footer-baby {
    color: #564861 !important;
}
.classic-venue-baby {
    background-color: black;
}
.classic-dance-sm-baby {
    color: #564861;
}
.body-style-classic-baby {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.classic-baby-style {
    border: 2px solid #000000;
    border-radius: 8px;
    height: 100%;
    max-width: 100%;
    margin: 10px;
    background-image: url("../../../assets/img/Other/texturedBackground.png");
}