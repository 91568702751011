
.body-fonts {
    font-family: Arial, Helvetica, Sans-serif;
}
img {
    max-width: 100%;
    height: auto;
}
.fullCard {
    max-height: "1300px";
    border: "2px solid #564861";
    border-radius: "8px";
    margin: "10px";
}
.title-wedding {
    margin-top: 0px;
    margin-bottom: 0px;
}

/* vietnamese */
@font-face {
    font-family: 'Dancing Script';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Rep8ltA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Dancing Script';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3ROp8ltA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Dancing Script';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
.title-wedding h1 {
    font-family: 'Dancing Script';
    font-weight: bold;
    font-style: normal;
    font-size: 100px;
    margin: 5px 0;
}

.title-wedding h2 {
    font-family: 'Dancing Script', cursive;
    font-weight: bold;
    font-style: normal;
    margin: 0;
    padding: 0;
    font-size: 24px;
    line-height: 1;
}
.title-wedding h3 {
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 24px;
    color: #674ea7;
    margin-top: 5px;
}
.title-shower h1 {
    font-family: 'Dancing Script', cursive;
    font-weight: bold;
    font-style: normal;
    font-size: 60px;
    margin: 5px 0;
}
.title-shower h2 {
    font-family: 'Dancing Script', cursive;
    font-weight: bold;
    font-style: normal;
    margin: 0;
    padding: 0;
    font-size: 24px;
    line-height: 1;
}
.title-shower {
    margin-top: 0px;
    margin-bottom: 0px;
}
.title-shower h3 {
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 24px;
    color: #674ea7;
    margin-top: 5px;
}
.title-baby h1 {
    font-family: 'Dancing Script', cursive;
    font-weight: bold;
    font-style: normal;
    font-size: 60px;
    margin: 5px 0;
}
.title-baby h2 {
    font-family: 'Dancing Script', cursive;
    font-weight: bold;
    font-style: normal;
    margin: 0;
    padding: 0;
    font-size: 24px;
    line-height: 1;
}
.title-baby {
    margin-top: 0px;
    margin-bottom: 0px;
}
.title-baby h3 {
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 24px;
    color: #674ea7;
    margin-top: 5px;
}
.dance-med {
    font-family: 'Dancing Script', cursive;
    font-weight: bolder;
    font-style: normal;
    font-size: 32px;
    color: #674ea7;
    margin: 16px;
    text-align: center;
}
.fontSmaller {
    font-size: 10px;
}
.wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    /* padding: 20px; */
    text-align: center;
    /* min-height:100%; */
}
* {
    /* margin: 0;
    padding: 0; */
    font-family: 'Arvo', serif;
}
.my-circle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
    z-index: 50;
    /* background: rgb(158, 18, 18); */
    /* background-color: #aaa9ad; */
    background-image: url("../img/Other/envelopeBackground.jpg");
    border: 3px solid #aaa9ad;
    color: #000;
    text-align: center;
    font: 17px Arial, sans-serif;
    left: 50%;
    position: absolute;
    margin-left: -25px;
    top: 50%;
    margin-top: -25px;
}
.my-circle-woman {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
    z-index: 50;
    /* background: rgb(158, 18, 18); */
    /* background-color: #aaa9ad; */
    background-image: url("../img/Other/envelopeBackground.jpg");
    border: 3px solid #aaa9ad;
    color: #000;
    text-align: center;
    font: 22px Arial, sans-serif;
    left: 50%;
    position: absolute;
    margin-left: -25px;
    top: 50%;
    margin-top: -25px;
}
.body-style-regular-save-date {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.body-style-custom-save-date {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.body-style-regular-wedding {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.body-style-custom-wedding {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.body-style-regular-shower {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.body-style-custom-shower {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.body-style-regular-baby {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.body-style-custom-baby {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.spacing {
    margin-top: 0px;
    margin-bottom: 0px;
}
#time {
    width: auto;
    height: auto;
    padding-bottom: 10px;
    background-color: #eee;
    border-radius: 20px;
    font-size: 16px;
}
.venue a {
    text-decoration: none;
    color: #fff;
}
.containers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 10px;
    overflow: hidden;
}
.block {
    width: 180px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
}
.pricing {
    border-color: #1DAEE0;
    border-style: solid;
    background-color: transparent;
    border-width: 3px;
    border-radius: 8px;
    margin: 3px;
}
.days {
    background: linear-gradient(90deg, #b33a46, #9b313c);
}
.hours {
    background: linear-gradient(90deg, #a7c3cd, #90a9b2);
}
.minutes {
    background-color: #874562;
}
.seconds {
    background-color: #145689;
}
.date {
    color: #874562;
    font-weight: 900;
}
.place {
    color: #874562;
    font-weight: 900;
}
.phone {
    color: #874562
}
.myDiv {
    position: relative;
    z-index: 1;
}
.watermark {
    background: url('../img/Logos/logo_transparent.png') center center;
    opacity: 0.05;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.end-msg {
    width: auto;
    height: auto;
    padding: 12px;
    background: linear-gradient(90deg, #b33a46, #9b313c);
    border-radius: 20px;
    font-size: 14px;
    color: #fff;
    font-size: 24px;
}
a {
    text-decoration: none;
}
.twitter {
    color: #874562;
}
.music {
    display: none;
}
.actions {
    display: flex;
    font-size: 12px;
    letter-spacing: 0pt;
    justify-content: center;
    margin-top: 10px;
}
.display-none {
    display: none;
}
ul {
    list-style-type: none;
    text-align: left;
}

a {
    color: #173962;
}