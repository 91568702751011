.top-right-decoration-goldDots {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    max-width: 40%;
}
.bottom-left-decoration-goldDots {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    max-width: 40%;
}
.goldDots-venue-baby {
    background-color: #695746;
}
.goldDots {
    /* background: -webkit-linear-gradient(120deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9));
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9));*/
    background: red;
    pointer-events: none;
    position: absolute
}
.goldDots {
    background: #695746;
}
.goldDots-happiness-baby {
    color: black !important;
}
.goldDots-header-style-baby {
    color: black !important;
}
.goldDots-man-name-baby {
    color: #695746;
}
.goldDots-woman-name-baby {
    color: #695746;
}
.goldDots-and-sign-baby {
    color: black;
}
.goldDots-dance-sm-baby {
    color: black;
}
.goldDots-title-baby {
    margin-top: 50px !important;  
    margin-bottom: 0px;
}
.goldDots-name-style-baby {
    color: black;
    margin-top: 5px;
    margin-bottom: 0;
    text-transform: uppercase;
    letter-spacing: 14px;
    font-size: 32px;
}
.goldDots-invitation-footer-baby {
    color: #695746 !important;
}
.fontsize30{
    font-size: 30px;
}
.goldDots-save-date-baby {
    font-family: 'Snell Roundhand', cursive;
    font-weight: bold;
    font-style: normal;
    font-size: 60px;
    margin: 15px 0;
    color: #695746;
}
.goldDots-date-style-baby {
    border: 2px solid #695746;
    border-radius: 8px;
    max-height: 500px;
    zoom:100%;
    max-width: 100%;
    margin: 10px;
    background: white;
}
.body-style-goldDots-baby {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }
 .goldDots-baby-style {
    border: 2px solid #695746;
    border-radius: 8px;
    height: 100%;
    max-width: 100%;
    margin: 10px;
    background-image: url("../../../assets/img/Other/texturedBackground.png");
}