.classic-man-name-wedding {
    color: black !important;
}
.classic-and-sign-wedding {
    color: black !important;
}
.classic-woman-name-wedding {
    color: black !important;
}
.classic-happiness-wedding {
    color: black !important;
}
.classic-venue-wedding {
    background-color: black;
}
.classic-dance-sm-wedding {
    color: #564861;
}
.classic-bottom-right-text-wedding {
    margin-bottom: 60px;
    margin-top: 0px;
    text-align: center;
    color: #564861;
}
.classic-bottom-left-wedding {
    color: #564861;
    letter-spacing: 3px;
    padding-top: 5px;
    bottom:20px;
    text-align: left;
}
.classic-bottom-right-wedding {
    color: #564861;
    letter-spacing: 3px;
    padding-top: 5px;
    display: inline;
    float: right;
}
.body-style-classic-wedding {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.classic-wedding-style {
    border: 2px solid #000000;
    border-radius: 8px;
    height: 100%;
    max-width: 100%;
    margin: 10px;
    background-image: url("../../../assets/img/Other/texturedBackground.png");
}