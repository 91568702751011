.wreath-and-sign-save-date {
    color: #d9af43 !important;
}
.wreath-dance-sm-save-date {
    color: black !important;
}
.wreath-header-style-save-date {
    color: black !important;
}
.wreath-invitation-footer-save-date {
    color: black !important;
}
.wreath-bottom-text-save-date {
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: center;
    color: black;
}
.wreath-name-style-save-date {
    color: black;
}
.wreath-save-date-text {
    color: #d9af43;
}
.wreath-date-style {
    border: 2px solid #d9af43;
    border-radius: 8px;
    max-height: 900px;
    zoom:100%;
    max-width: 100%;
    background-image: url("../../../assets/img/Other/texturedBackground.png");
}
.body-style-wreath-save-date {
    zoom:75%;
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }
 .wreath-venue-save-date {
    background-color: #d9af43;
}