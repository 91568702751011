.classic-and-sign-save-date {
    color: black !important;
}
.classic-dance-sm-save-date {
    color: #564861;
}
.classic-bottom-text-save-date {
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: center;
    color: #564861;
}
.classic-name-style-save-date {
    color: #564861;
}
.classic-save-date-text {
    color: black;
}
.classic-date-style {
    zoom:100%;
    border: 2px solid #564861;
    border-radius: 8px;
    max-height: 600px;
    max-width: 100%;
    margin: 10px;
    background-image: url("../../../assets/img/Other/texturedBackground.png");
}
.body-style-classic-save-date {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.classic-venue-save-date {
    background-color: black;
}