.wreath-man-name-wedding {
    color: #d9af43 !important;
}
.wreath-and-sign-wedding {
    color: black !important;
}
.wreath-woman-name-wedding {
    color: #d9af43 !important;
}
.wreath-venue-wedding {
    background-color: #d9af43;
}
.wreath-happiness-wedding {
    color: #d9af43 !important;
}
.wreath-title-wedding {
    margin-top: 80px !important;  
    margin-bottom: 0px;
}
.wreath-dance-sm-wedding {
    color: black !important;
}
.wreath-invitation-footer-wedding {
    color: black !important;
}
.wreath-bottom-right-text-wedding {
    margin-bottom: 60px;
    margin-top: 0px;
    text-align: center;
    color: black;
}
.wreath-bottom-left-text-wedding {
    color: black;
    letter-spacing: 3px;
    padding-top: 5px;
    text-align: left;
}
.wreath-bottom-right-text-wedding {
    color: black;
    letter-spacing: 3px;
    padding-top: 5px;
    display: inline;
    float: right;
}
.body-style-wreath-wedding {
    zoom:75%;
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }
.wreath-wedding-style {
    border: 2px solid #D9AF43;
    border-radius: 8px;
    height: 100%;
    max-width: 100%;
    margin: 10px;
    max-height: 1200px;
    background-image: url("../../../assets/img/Other/texturedBackground.png");
}