.top-right-decoration-silverGlitter {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    max-width: 40%;
}
.bottom-left-decoration-silverGlitter {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    max-width: 40%;
}
.silverGlitter-venue-baby {
    background-color: #666869;
}
.silverGlitter {
    /* background: -webkit-linear-gradient(120deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9));
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9));*/
    background: red;
    pointer-events: none;
    position: absolute
}
.silverGlitter {
    background: #666869;
}
.silverGlitter-happiness-baby {
    color: black !important;
}
.silverGlitter-header-style-baby {
    color: black !important;
}
.silverGlitter-man-name-baby {
    color: #666869;
}
.silverGlitter-woman-name-baby {
    color: #666869;
}
.silverGlitter-and-sign-baby {
    color: black;
}
.silverGlitter-dance-sm-baby {
    color: black;
}
.silverGlitter-title-baby {
    margin-top: 50px !important;  
    margin-bottom: 0px;
}
.silverGlitter-name-style-baby {
    color: black;
    margin-top: 5px;
    margin-bottom: 0;
    text-transform: uppercase;
    letter-spacing: 14px;
    font-size: 32px;
}
.silverGlitter-invitation-footer-baby {
    color: #666869 !important;
}
.silverGlitter-save-date-baby {
    font-family: 'Snell Roundhand', cursive;
    font-weight: bold;
    font-style: normal;
    font-size: 60px;
    margin: 15px 0;
    color: #666869;
}
.silverGlitter-date-style-baby {
    border: 2px solid #B9B9B9;
    border-radius: 8px;
    max-height: 500px;
    zoom:100%;
    max-width: 100%;
    margin: 10px;
    background: white;
}
.body-style-silverGlitter-baby {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }
 .silverGlitter-baby-style {
    border: 2px solid #B9B9B9;
    border-radius: 8px;
    height: 100%;
    max-width: 100%;
    margin: 10px;
    background-image: url("../../../assets/img/SilverGlitter/background.png");
}