.wreath-man-name-shower {
    color: #d9af43 !important;
}
.wreath-and-sign-shower {
    color: black !important;
}
.wreath-woman-name-shower {
    color: #d9af43 !important;
}
.wreath-venue-shower {
    background-color: #d9af43;
}
.wreath-happiness-shower {
    color: #d9af43 !important;
}
.wreath-title-shower {
    margin-top: 80px !important;  
    margin-bottom: 0px;
}
.wreath-dance-sm-shower {
    color: black !important;
}
.wreath-invitation-footer-shower {
    color: black !important;
}
.body-style-wreath-shower {
    zoom:75%;
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }
.wreath-shower-style {
    border: 2px solid #D9AF43;
    border-radius: 8px;
    height: 100%;
    max-width: 100%;
    margin: 10px;
    max-height: 1200px;
    background-image: url("../../../assets/img/Other/texturedBackground.png");
}