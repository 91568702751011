@media only screen and (max-width: 600px) {
    .bottom-text-save-date {
        font-family: 'Dancing Script', cursive;
        font-weight: bolder;
        font-style: normal;
        font-size: 2.8vw;
        white-space: nowrap;
    }
    .venue-save-date {
        width: 20vw;
        height: 25px;
        border-radius: 16px;
        text-align: center;
        font-size: 1.3vw;
        color: #fff;
        padding: 8px;
        margin-right: 8px;
        margin-top: 10px;
        cursor: pointer;
    }
    .downloadButton-save-date {
        display: none;
    }  
    .dance-sm-save-date {
        font-family: 'Dancing Script', cursive;
        font-weight: bolder;
        font-style: normal;
        font-size: 6vw;
        white-space: nowrap;
        margin-top: 0;
        margin-bottom: 5px;
        text-align: center;
        letter-spacing: 1vw;
    }
    .wreath-save-date {
        position: absolute;
        left: 50%;
        top:0px;
        margin-top: -75px;
        margin-left: -237.5px;
        background-repeat: no-repeat;
        background-image: url('../../../assets/img/Other/goldWreath.png');
        min-width: 475px;
        min-height: 405px;
        transform: scale(.5);
    }
    .centerInitial-save-date {
        position: relative;
        padding-top: 70%;
        color:black;
        font-size: 10vmax;
        left: 50%;
        display: block;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .top-center-decoration-save-date {
        transform: scale(.6);
        top: 0;
        align-items: center;
        text-align: center;
        margin: auto;
        display: block;
    }
    .bottom-center-decoration-save-date {
        margin-left: auto;
        transform: scale(.6);
        display: block;
        margin-right: auto;
    }
    .name-style-save-date {
        margin-top: .5px;
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 2vw;
        font-size: 5.5vw;
    }
    .save-date {
        font-family: 'Snell Roundhand', cursive;
        font-weight: bold;
        font-style: normal;
        font-size: 10vw;
        margin: 3px 0;
    }
    .city-state-save-date {
        color: #564861;
        text-align: center;
        font-size: 3vw;
        margin-top: 5px;
        margin-bottom: 1px;
        background-color: transparent;
        text-transform: uppercase;
    }
    .message-save-date {
        color: #564861;
        margin-top: .5px;
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: .5vw;
        font-size: 4vw;
    }
}

@media only screen and (min-width: 601px) {
    .bottom-text-save-date {
        font-family: 'Dancing Script', cursive;
        font-weight: bolder;
        font-style: normal;
        font-size: 1.3vw;
        white-space: nowrap;
    }
    .venue-save-date {
        width: 7vw;
        height: 35px;
        border-radius: 16px;
        text-align: center;
        font-size: .6vw;
        color: #fff;
        padding: 8px;
        margin-right: 8px;
        margin-top: 10px;
        cursor: pointer;
    }
    .dance-sm-save-date {
        font-family: 'Dancing Script', cursive;
        font-weight: bolder;
        font-style: normal;
        font-size: 1.5vw;
        white-space: nowrap;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        letter-spacing: 2px;
    }
    .invitation-footer-save-date {
        color: #252525;
        text-align: center;
        font-size: .7vw;
        margin: 20px;
        background-color: transparent;
    }
    .wreath-save-date {
        position: absolute;
        left: 50%;
        top:0px;
        margin-top: -55px;
        margin-left: -237.5px;
        background-repeat: no-repeat;
        background-image: url('../../../assets/img/Other/goldWreath.png');
        min-width: 475px;
        min-height: 405px;
        transform: scale(0.7);
    }
    .centerInitial-save-date {
        position: relative;
        padding-top: 70%;
        color:black;
        font-size: 4vmax;
        left: 50%;
        display: block;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .wreath-title-save-date {
        margin-top: 70px !important;  
        margin-bottom: 0px;
    }
    .top-center-decoration-save-date {
        top: 0;
        align-items: center;
        text-align: center;
        height: 70px;
        margin: auto;
        display: block;
    }
    .bottom-center-decoration-save-date {
      margin-top: 40px !important;
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: 70px;
    }
    .name-style-save-date {
        margin-top: 5px;
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 1vw;
        font-size: 1.7vw;
    }
    .save-date {
        font-family: 'Snell Roundhand', cursive;
        font-weight: bold;
        font-style: normal;
        font-size: 3.7vw;
        margin: 15px 0;
    }
    .city-state-save-date {
        color: #564861;
        text-align: center;
        font-size: 1vw;
        margin-top: 30px;
        margin-bottom: 10px;
        background-color: transparent;
        text-transform: uppercase;
    }
    .message-save-date {
        color: #564861;
        margin-top: 5px;
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 1vw;
    }
}