.classic-man-name-shower {
    color: black !important;
}
.classic-and-sign-shower {
    color: black !important;
}
.classic-woman-name-shower {
    color: black !important;
}
.classic-happiness-shower {
    color: black !important;
}
.classic-venue-shower {
    background-color: black;
}
.classic-dance-sm-shower {
    color: #564861;
}
.body-style-classic-shower {
    width:inherit;
    align-items:center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.classic-shower-style {
    border: 2px solid #000000;
    border-radius: 8px;
    height: 100%;
    max-width: 100%;
    margin: 10px;
    background-image: url("../../../assets/img/Other/texturedBackground.png");
}