:root {
  --base: #3a3a3a;
  --base-light: #424242;
  --base-dark: #333333;
  --shadow: #5c5c5c;
  --letter: #474747;
}


* {
  border: 0;
  margin: 0;
  box-sizing: border-box;
}
.close {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 20px;
  margin-top: 20px;
  display: none;
  font-size: 30px;
  font-family: "Manjari", sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10;
}

@mixin center-by-all-means {
  display: flex;
  align-items: center;
  justify-content: center;
}

.envContainer {
  min-height: 100vh;
  background-size: cover;
  background-image: url("../img/Other/envelopeBackground.jpg");
  @include center-by-all-means;
}

.content {
  position: relative;
  perspective: 600px;
}

.shadow {
  position: absolute;
  width: 200px;
  height: 1px;
  background: transparent;
  border-radius: 30%;
  box-shadow: 50px 100px 10px 5px #eeeef3;
}

.letter {
  position: absolute;
  top: 10px;
  left: 15px;
  width: 280px;
  height: 160px;
  @include center-by-all-means;
  z-index: 20;
  background:transparent;
  .body {
    position: relative;
    width: 240px;
    height: 120px;
    @include center-by-all-means;
    color: var(--base);
   
    
    // .message {
    //   font-size: 40px;
    //   font-weight: 900;
    //   text-align: center;
    //   font-family: 'Great Vibes', cursive;
    // }
    .EnvelopeDiv {
      font-size: 40px;
      font-weight: 900;
      text-align: center;
      font-family: "Great Vibes", cursive;
      position: relative;
      overflow: hidden;
      height: 10vh;
      margin: auto;
      margin-top: -12px;
      border-radius: 8px; 
      width: 35%;
    }

  }
}

.envelope {
  position: relative;
  width: 300px; 
  height: 180px; 
  background: linear-gradient(#cccbd7 0.5px, var(--base) 0.5px) ;
  cursor: pointer;

}

.envelope::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 300px;
  box-sizing: border-box;
  z-index: 25;
  transform-origin: center top;
  transform: translate(0px, 0px);
  border-top: 115px solid;
  border-top-color:  var(--base-dark);
  filter: brightness(80%);
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
}

.envelope::after {
  content: '';
  position: absolute;
  top: 0;
  width: 300px; 
  height: 180px;
  background: 
    //bottom-right
    linear-gradient(30deg, var(--base-dark) 47%, var(--shadow) 50%, var(--base) 50%) 150px 90px/ 150px 90px no-repeat,
    
    //top-left
    linear-gradient(31deg, var(--base) 49%, var(--shadow) 50%, transparent 50%) 0px 0px/ 152px 90px no-repeat,
    
    //bottom-left
    linear-gradient(150deg, var(--base) 50%, var(--shadow) 50%, var(--base-dark) 53%) 0px 90px/ 151px 90px no-repeat,
    
    //top-right
    linear-gradient(148.7deg, transparent 50%, var(--shadow) 50%, var(--base) 51%) 150px 0px/ 150px 90px no-repeat; 
}
@media only screen and (max-width: 600px) {
  .linksEnvelope {
    padding: 0;
    z-index: 5;
    position: fixed;
    bottom: 0;
    left: 0%;
  }
}
@media only screen and (min-width: 601px) {
  .linksEnvelope {
  
    -ms-flex-pack: left!important;
    justify-content: left!important;
    padding: 0;
    z-index: 0;
    position: fixed;
    left: 0;
    bottom: 5%;
  }
}

.text-logo {
  padding-top: 8px;
  padding-left: 5px;
}