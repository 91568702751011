

.envelope::after {
  left: 0;
  box-sizing: border-box;
  transform-origin: top;
  content: '';
  position: absolute;
  top: 0;
  width: 300px; 
  height: 180px; 
  z-index: 25;
  background: 
    //bottom-right
    linear-gradient(30deg, var(--base-dark) 47%, var(--shadow) 50%, var(--base) 50%) 150px 90px/ 150px 90px no-repeat,
    
    //top-left
    linear-gradient(31deg, var(--base) 49%, var(--shadow) 50%, transparent 50%) 0px 0px/ 152px 90px no-repeat,
    
    //bottom-left
    linear-gradient(150deg, var(--base) 50%, var(--shadow) 50%, var(--base-dark) 53%) 0px 90px/ 151px 90px no-repeat,
    
    //top-right
    linear-gradient(148.7deg, transparent 50%, var(--shadow) 50%, var(--base) 51%) 150px 0px/ 150px 90px no-repeat; 
}